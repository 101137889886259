<template>
    <modal class="callHistoryModel" :isShow="isShow">
        <div class="header">来电显示记录
            <i class="iconfont icon-guanbi" @click="hide()"></i>
        </div>
        <div class="modal-body">
            <div class="filter-box">
                <div class="date-box">
                    <span>来电时间：</span>
                    <el-date-picker class="from-date"
                        v-model="businessHours"
                        type="daterange"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :default-time="defaultTime"
                    > </el-date-picker>
                </div>
                <div class="flex-box">
                    <div class="lable-text">电话号码：</div>
                    <div class="from-input">
                        <input-pattern pattern="phone" v-model="phone"/>
                    </div>
                    <div class="query-bnt" @click="loadData()">查询</div>
                </div>
                
            </div>
            
            <div class="list-box">
                <div class="table-box" ref="kitNameTable">
                    <el-table class="el-table--scrollable-y" style="width: 100%;" border v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}"
                        :data="tableList" highlight-current-row>
                        <el-table-column prop="phone" label="来电电话" min-width="65"></el-table-column>
                        <el-table-column prop="time" label="来电时间" min-width="58"></el-table-column>
                        <el-table-column prop="User_Name" label="客户姓名" min-width="78"></el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn" @click="hide()">取消</button>
            <button class="btn selected">确认</button>
        </div>
    </modal>
</template>

<script>
// 来电记录
export default {
    name:'callHistoryModel',
    props:{
        isShow:Boolean,
    },
    data(){
        return{
             /**默认日期范围 */
            defaultTime:[new Date(),new Date()],
            /**选中日期范围 */
            businessHours:[new Date(),new Date()],
            //号码
            phone:"",
            tableList:[]
        }
    },
    mounted(){
        this.$nextTick(()=>{
            this.init();
        });
    },
    watch:{
        isShow(newValue){
            if(newValue){
                this.init();
            }
        }
    },
    methods:{
        /**初始化数据 */
        init(){
            for(let i=0;i<10;i++){
                this.tableList.push({
                    phone:"1521235448"+i,
                    time:"2024-05-11",
                    User_Name:"测试"+i
                })
            }
        },
        hide(){
            this.$emit("close");
        },
        confirm(){
        },
        /**撤销 */
        revoke(){
            this.$confirm("你确定要撤销这笔定金吗？", "提示", {
				confirmButtonText: "确定",
				cancelButtonText:"取消",
                type: 'warning',
				callback: (name) => {
					if(name=='confirm'){
						console.log("确定")
					}
				},
			});
        }
    }
}
</script>

<style lang="scss">
@import './callHistoryModel.scss'
</style>